import BlogItem from "./BlogItem"

const Blog = () => {
    return (
        <section className="blog">
            {/*<div className="page-top">
              <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-title">Blog</h1>
                            <h2 className="page-description">Blog</h2>
                        </div>
                    </div>
                </div>
            </div>*/}
            <div className="page-content">
                <div className="container">
                    <div className="row"> 
                        <BlogItem img="img/services/tenant_screening.jpg" link="blog-1" title="Tenant Screening and Placement" />
                        <BlogItem img="img/services/rentcollection.jpg" link="blog-2" title="Rent Collection" />
                        <BlogItem img="img/services/property_maintanance.jpg" link="blog-3" title="Property Maintenance and Repairs"/>
                        <BlogItem img="img/services/lease_agreement.jpg" link="blog-4" title="Lease Agreement Management"/>

                        <BlogItem img="img/services/property_inspection.jpg" link="blog-6" title="Property Inspections"/>
                        <BlogItem img="img/services/legal_compliance.jpg" link="blog-8" title="Legal Compliance"/>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default Blog