import React from "react"
import {Link} from "react-router-dom"
import logoImage from "../assets/images/logo.png";
{/*import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAddressBook,
    faCaretDown, faContactBook,
    faGear,
    faGears,
    faHome,
    faMobile, faPlay,
    faServer
} from "@fortawesome/free-solid-svg-icons";*/}
const Header = () => {
    return (
        <div className="header">
            <div className="container">
                <nav className="navbar navbar-expand-lg navbar-light">
                    <div className="container-fluid">
                        <Link className="navbar-brand" to="/">
                            <div className="d-flex align-items-center">
                                <img src={logoImage} width="140px" height="90px" alt="Image Description" />
                                {/*<i className="fas fa-home"></i>*/}
                                {/* <span className="ms-2">
                                    MB
                           </span>*/}
                            </div>
                        </Link>


                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item">
                                    <Link style={{
                                        borderRadius: "40px",
                                    }} className="nav-link with-background" to="/">Home  {/*<FontAwesomeIcon icon={faHome} />*/}</Link>
                                </li>
                                <li className="nav-item">
                                    <Link style={{
                                        borderRadius: "40px",
                                    }}  className="nav-link with-background" to="/blog">Services {/*<FontAwesomeIcon icon={faGears}  />*/}</Link>
                                </li>
                                <li className="nav-item">
                                    <Link style={{
                                        borderRadius: "40px",
                                    }}  className="nav-link with-background" to="/about">About {/*<FontAwesomeIcon icon={faAddressBook} />*/}</Link>
                                </li>
                                {/*<li className="nav-item">
                                    <Link className="nav-link" to="#">Category <i className="fas fa-chevron-down"></i></Link>
                                    <ul className="sub-ul">
                                        <li><Link to="#">item</Link></li>
                                        <li><Link to="#">item</Link></li>
                                        <li><Link to="#">item</Link></li>
                                    </ul>
                                </li>*/}
                                <li className="nav-item">
                                    <Link style={{
                                        borderRadius: "40px",
                                    }} className="nav-link with-background" to="/contact">Contact {/*<FontAwesomeIcon icon={faMobile} /> */}</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    )
}

export default Header;