import Title from "./Title"
import TeamItem from "./TeamItem"

const TeamList = () => {
    const title = {
        text: "Our Team",
        //description: "Lorem ipsum dolor sit ame"
    }
    return (
        <section className="section-teams">
            <div className="container">
                <Title title={title.text} description={title.description} />
                <div className="row">
                    <TeamItem img="/img/lee.png" name="Leroy T Chiyangwa" title="CRM Manager"/>
                    <TeamItem img="/img/shanaz.png"  name="Shanaz" title="Hot Line Head"/>
                    <TeamItem img="/img/bong.png"  name="Bongani" title="Marketing Head"/>

                </div>
            </div>
        </section>
    )
}

export default TeamList;