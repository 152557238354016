
import Title from "./Title"
import FlatItem from "./FlatItem"

const FlatList = () => {
    const title = {
        text: "Recently Managed Homes",
       // description: "Lorem ipsum dolor sit ame"
    }
    return (
        <section className="section-all-re">
            <div className="container">
                <Title title={title.text} description={title.description} />
                <div className="row">
                    <FlatItem description="7 Belfast Designed BedRoom" img="img/rooms/bedroom.jpg"/>
                    <FlatItem description="7112 Ruwa Designed" img="img/rooms/bedroom2.jpg"/>
                    <FlatItem description="8 Zimre Arted BedRooms" img="img/rooms/bedroom3.jpg"/>

                    <FlatItem description="7 Belfast Designed BedRoom" img="img/rooms/kitchen.jpg"/>
                    <FlatItem description="7112 Ruwa Designed" img="img/rooms/kitchen2.jpg"/>
                    <FlatItem description="8 Zimre Arted BedRooms" img="img/rooms/kitchen3.jpg"/>

                </div>
            </div>
        </section>
    )

}

export default FlatList;