const About = () => {
    return (
        <section className="about">
            {/* <div className="page-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-title">About</h1>
                            <h2 className="page-description">About</h2>
                        </div>
                    </div>
                </div>
            </div>*/}
            <div className="page-content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <img src="/img/rooms/bedroom.jpg" alt="product" className="w-100" />
                        </div>
                        <div className="col-lg-6">
                            <div className="about-item">
                                <div className="title">
                                    SNS Home Management
                            </div>
                                <div className="about-text">
                                    At SNS Property Management, we specialize in providing exceptional property management services for residential, commercial, and industrial properties. With years of experience in the industry, we are dedicated to helping property owners maximize their investments while ensuring a seamless experience for tenants.
                            </div>
                                {/* <div className="about-features">
                                    <p className="about-feature"><i className="fas fa-long-arrow-alt-right"></i> Lorem ipsum is simply</p>
                                    <p className="about-feature" ><i className="fas fa-long-arrow-alt-right"></i> Lorem ipsum is simply</p>
                                    <p className="about-feature"><i className="fas fa-long-arrow-alt-right"></i> Lorem ipsum is simply</p>

                                </div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About